import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';
import TwitterIcon from '@static/icons/twitter.svg';
import FacebookIcon from '@static/icons/facebook.svg';
import WoannaIcon from '@static/icons/woanna.svg';

const ICONS = [
  /*{
    icon: GithubIcon,
    link: 'https://github.com/ajayns/gatsby-absurd',
    currentColor: 'white',
  },
  {
    icon: InstagramIcon,
    link: 'https://instagram.com/ajay_ns',
  },*/
  {
    icon: TwitterIcon,
    link: 'https://twitter.com/rocketbatch',
  },
  {
    icon: FacebookIcon,
    link: 'https://facebook.com/rocketbatch',
  },
  
];


const StyledInternalLink = styled(Link)`
  color: #30354A;
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;

const StyledExternalLink = styled.a`
  color: #30354A;
  text-decoration: none;
 
  &:hover {
    color: ${props => props.theme.color.black.regular};
  }
`;





const Footer = () => (
  
  <StaticQuery
    query={graphql`
      query {
        art_pot: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      
      <React.Fragment>
        
        <FooterWrapper>
          <StyledContainer>

            <Copyright> 
             
             {/*
              <ExternalLink href={"/#header"}>
                  <img width="30px" src={WoannaIcon} alt="link"/>
              </ExternalLink>
            */}
              <h4 style={{color:'#30354A'}}>Contact</h4>
              <h5 style={{color:'#30354A'}}><br /><br /><br />info@rocketbatch.com<br />+ 41 797921370</h5>
              
              

              <hr />
              <h6>
              © 2021 rocketbatch
              </h6>
            </Copyright>
              
            
            

            <MediaIcons>

            {ICONS.map(({ icon, link }) => (
                <ExternalLink href={link}>
                  <img src={icon} alt="link"/>
                </ExternalLink>
              ))}
               </MediaIcons>
              <div style={{textAlign:'right'}}>
              <br />
              {/* 
              <h5 style={{color:'#0056E5'}}>  
              <StyledInternalLink to="/blog" style={{color:'#0056E5'}}>
                    Our Blog //
              </StyledInternalLink>
              </h5>
              */}

              <h5 style={{color:'#0056E5'}}>  
              <StyledInternalLink to="/en/sla" style={{color:'#0056E5'}}>
                    Terms of service //
              </StyledInternalLink>
              </h5>

              <h5 style={{color:'#0056E5'}}>  
              <StyledInternalLink to="/en/privacy-policy" style={{color:'#0056E5'}}>
                    Terms of privacy //
              </StyledInternalLink>
              </h5>

              <h5 style={{color:'#0056E5'}}>  
              <StyledInternalLink to="/security" style={{color:'#0056E5'}}>
                    Security //
              </StyledInternalLink>
              </h5>
              <h5 style={{color:'#42B983'}}>  
              <StyledExternalLink href="https://rocketbatch.com/docs/#/" style={{color:'#42B983'}}>
                    Support / Documentation
              </StyledExternalLink>
              </h5>
              </div>
              
           

            
            </StyledContainer>
        </FooterWrapper>

        <Art>
          <Img
            fluid={data.art_pot.childImageSharp.fluid}
            style={{ width: '40%', maxWidth: '100%', marginBottom: 0 }}
          />
        </Art>

      </React.Fragment>
    )}
  />
);

const MediaIcons = styled.div`
  display: flex;

  img {
    margin: 0 8px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-top: 40px;
  }
`;

const FooterWrapper = styled.footer`
border-top: 0.1px solid #EBEBEB;
  background-color: ${props => props.theme.color.primary};
  padding: 32px 0;
`;

const Copyright = styled.div`
  font-family: ${props => props.theme.font.secondary};
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.regular};

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const Art = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  margin-top: 0px;
  background-color: #fff;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export default Footer;
